import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomToolTipInfo } from "../../../common/smallComponents.js/CustomToolTip";
import { compareTwoDates } from "../../../functions/StrFunctions";

const OtherInfo = (p) => {
    const [use, setUse] = useState({ in: "", ln: "", ik: "", telp: "", ec: "", wn: "" });
    const [consoByMach, setConsoByMach] = useState({
        in: { qty: 0, month: [], avg: 0 },
        ln: { qty: 0, month: [], avg: 0 },
        wn: { qty: 0, month: [], avg: 0 },
        ik: { qty: 0, month: [], avg: 0 },
        telp: { qty: 0, month: [], avg: 0 },
        ec: { qty: 0, month: [], avg: 0 }
    });

    const [consoTot, setConsoTot] = useState({ qty: 0, month: [], avg: 0 });
    const authMvt = "E02,E03,S03,S01,SBE";

    useEffect(() => {
        if (!p.articleInfo.ZN32PA) return;

        var split = p.articleInfo.ZN32PA.split("#");
        setUse({
            in: split[0].split(":")[1],
            wn: split[1].split(":")[1],
            ln: split[2].split(":")[1],
            ik: split[3].split(":")[1],
            telp: split[4].split(":")[1],
            ec: split[5].split(":")[1]
        })
    }, [p.articleInfo]);


    useEffect(() => {
        if (!p.moves) return;

        // Get 6 months earlier
        let limit = new Date();
        limit.setMonth(limit.getMonth() - 12);

        var sixMonthHistory = p.moves.filter((a) => compareTwoDates(new Date(a.DATMO), limit) < 0)

        var tot = {...consoTot};
        var byMach = { ...consoByMach };

        for (let i = 0; i < sixMonthHistory.length; i++) {
            var row = sixMonthHistory[i];
            var qty = getQty(row.CDMVO, row.QRUSO);

            //Calculation by machine
            if (row.typ_config && qty) {
                var typ = getMachineType(row.typ_config);
                var month = new Date(row.DATMO).getMonth() + 1;

                if (typ) {
                    byMach[typ].qty += qty;
                    if (byMach[typ].month.indexOf(month) === -1) byMach[typ].month.push(month);
                }
            }

            //Total calculation
            if( qty ){
                tot.qty += qty;
                if (tot.month.indexOf(month) === -1) tot.month.push(month);
            }
        }

        if (byMach.ec.qty > 0) byMach.ec.avg = (byMach.ec.qty / byMach.ec.month.length).toFixed(1)
        if (byMach.ik.qty > 0) byMach.ik.avg = (byMach.ik.qty / byMach.ik.month.length).toFixed(1)
        if (byMach.in.qty > 0) byMach.in.avg = (byMach.in.qty / byMach.in.month.length).toFixed(1)
        if (byMach.ln.qty > 0) byMach.ln.avg = (byMach.ln.qty / byMach.ln.month.length).toFixed(1)
        if (byMach.telp.qty > 0) byMach.telp.avg = (byMach.telp.qty / byMach.telp.month.length).toFixed(1)
        if (byMach.wn.qty > 0) byMach.wn.avg = (byMach.wn.qty / byMach.wn.month.length).toFixed(1)
        
        if( tot.qty > 0 ) tot.avg = tot.qty/tot.month.length;

        setConsoByMach( byMach );
        setConsoTot(tot)
    
    }, [p.moves]);

    function getMachineType(config) {
        if (config.startsWith("TE-LP") || config.startsWith("BA15P")) return "telp";
        if (config.startsWith("EC")) return "ec";
        if (config.startsWith("IK")) return "ik";
        if (config.startsWith("IN") || config.startsWith("2Pack")) return "in";
        if (config.startsWith("LN")) return "ln";
        if (config.startsWith("WN")) return "wn";

        return null;
    }

    function getQty(codeMvt, qty) {
        if (authMvt.indexOf(codeMvt) === -1) return null;

        //The out from stock should be positive in our calcul
        return qty * -1;
    }

    return (

        <div>

            <div className="d-flex flex-wrap">
                {/** STOCK DETAILS */}
                <div className="card no-margin-top" style={{ width: "500px" }}>
                    <h6 className="text-center">Détail quantités en stock</h6>
                    <table>
                        <tr>
                            <td className="d-flex border-end" style={{ width: "300px" }}>
                                En stock
                                <CustomToolTipInfo props={{ tooltipText: "Stock qu'on possède dans nos magasin" }} />
                            </td>
                            <td>{p.articleInfo.QTSTL}</td>
                        </tr>
                        <tr className="border-bottom">
                            <td className="d-flex border-end" style={{ width: "300px" }}>
                                - En besoin affecté en stock
                                <CustomToolTipInfo props={{ tooltipText: "Qté d'articles de notre stock qui sont réservés aux affaires en cours" }} />
                            </td>
                            <td>{p.articleInfo.QTAFL}</td>
                        </tr>
                        <tr className="fw-bold">
                            <td className="d-flex border-end" style={{ width: "300px" }}>
                                = Disponible en stock
                                <CustomToolTipInfo props={{ tooltipText: "Qté réellement disponible" }} />
                            </td>
                            <td>{p.articleInfo.QTSTL - p.articleInfo.QTAFL}</td>
                        </tr>

                        <tr>
                            <td className="d-flex border-end" style={{ width: "300px" }}>
                                - En besoin non affecté en stock
                                <CustomToolTipInfo props={{ tooltipText: "Qté en affaire qui n'ont pas été reservés faute de stock suffisant" }} />
                            </td>
                            <td>{p.articleInfo.QTREL - p.articleInfo.QTAFL}</td>
                        </tr>

                        <tr className="border-bottom">
                            <td className="d-flex border-end" style={{ width: "300px" }}>
                                + En commande sur stock
                                <CustomToolTipInfo props={{ tooltipText: "Commande passé sur le stock en attente de livraison" }} />
                            </td>
                            <td>{p.articleInfo.QTCDL}</td>
                        </tr>

                        <tr className="fw-bold">
                            <td className="d-flex border-end" style={{ width: "300px" }}>
                                = Disponible à terme
                                <CustomToolTipInfo props={{ tooltipText: "Qté disponible en stock (voir plus haut) - besoin en attente d'affectation + commandes à venir" }} />
                            </td>
                            <td>{p.articleInfo.QTSTL - p.articleInfo.QTREL + p.articleInfo.QTCDL}</td>
                        </tr>
                    </table>
                </div>

                {/** ORDERS DETAILS */}
                {p.orders.length > 0 && <div className="card no-margin-top overflow-x-auto" style={{ maxWidth: "1000px" }}>
                    <h6 className="text-center">Détail des commandes sur stock en cours</h6>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Fournisseur</th>
                                <th scope="col">Qté</th>
                                <th scope="col">Prix</th>
                                <th scope="col">Affaire</th>
                                <th scope="col">Machine</th>
                                <th scope="col">Délai demandé</th>
                                <th scope="col">Date accusée recpt.</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                p.orders.map((v, k) => {
                                    return <tr key={"ord" + k}>
                                        <th scope="row">{v.NOCFE}</th>
                                        <td>{v.CDFOE}</td>
                                        <td>{v.QTESE}</td>
                                        <td>{v.PUNTE}</td>
                                        <td>{v.NOCCE}</td>
                                        <td>{v.NONNE}</td>
                                        <td>{v.DTLIE}</td>
                                        <td>{v.DTCOE}</td>
                                        <td>
                                            <a target="_blank" href={"/purchase/order/edition?order=" + v.NOCFE}>Détail</a>
                                        </td>
                                    </tr>
                                })
                            }

                        </tbody>
                    </table>
                </div>
                }

            </div>

            <div className="d-flex flex-wrap">
                {/** PRICES DETAILS */}
                <div className="card no-margin-top" style={{ width: "300px" }}>
                    <h6 className="text-center">Détail des prix</h6>

                    <table className="table">
                        <tbody>
                            <tr>
                                <td style={{ width: "180px" }}>Prix de revient</td>
                                <td>{p.articleInfo.PXREL}</td>
                            </tr>

                            <tr>
                                <td>Dernier prix d'achat</td>
                                <td>{p.articleInfo.DEPAL}</td>
                            </tr>

                            <tr>
                                <td>PMP</td>
                                <td>{p.articleInfo.PXMPL}</td>
                            </tr>

                            <tr>
                                <td>Dernier fournisseur</td>
                                <td>{p.articleInfo.supplier}</td>
                            </tr>

                            <tr>
                                <td colSpan={2}>
                                    <a target="_blank" href={"/article/info/stock/dynamic?article=" + p.articleCode}>Stock dynamique</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="card no-margin-top" style={{ maxWidth: "1000px" }}>
                    <h6 className="text-center">Moyenne d'utilisation de cet article</h6>

                    <table className="table text-center">
                        <tbody>
                            <tr>
                                <td></td>
                                <td>IN</td>
                                <td>WN</td>
                                <td>LN</td>
                                <td>IK</td>
                                <td>TELP</td>
                                <td>EC</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="d-flex text-start">
                                        Utilisation moyenne prévue
                                        <CustomToolTipInfo props={{ tooltipText: "Utilisation moyenne par machine renseignée lors de la crétion d'article" }} />
                                    </div>
                                </td>
                                <td>{use.in}</td>
                                <td>{use.wn}</td>
                                <td>{use.ln}</td>
                                <td>{use.ik}</td>
                                <td>{use.telp}</td>
                                <td>{use.ec}</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="d-flex text-start">
                                        Consommation moyenne
                                        <CustomToolTipInfo props={{ tooltipText: "Consommation moyenne mensuelle compte tenu des mouvement de stock sur 1 an" }} />
                                    </div>
                                </td>
                                <td>{consoByMach.in.avg || 0}</td>
                                <td>{consoByMach.wn.avg || 0}</td>
                                <td>{consoByMach.ln.avg || 0}</td>
                                <td>{consoByMach.ik.avg || 0}</td>
                                <td>{consoByMach.telp.avg || 0}</td>
                                <td>{consoByMach.ec.avg || 0}</td>
                            </tr>
                            <tr>
                                <td>Conso. moyenne tout type de machine</td>
                                <td colSpan={6}>{consoTot && consoTot.avg.toFixed(1)}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>

        </div>)
}

export default OtherInfo;