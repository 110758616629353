import React, { useState, useEffect, useRef } from "react";
import { getDeviceType } from "../functions/PageAnimation";
import { Fragment } from "react";
import CheckConnDao from "../services/common/CheckConnDao";
import { getFrFormat } from "../functions/StrFunctions";
import i18n from "../i18n";
import { useCookies } from "react-cookie";
import { arrayRemoveElt } from "../functions/ArrayFunctions";
import NavigationSearch from "./settings/comp/NavigationSearch";
import Miscellaneous from "../services/common/Miscellaneous";


const Home = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  var typeDevice = getDeviceType();
  const [list, setList] = useState([]);
  const [read, setRead] = useState([]);
  const canNavigate = user && (user.roles.includes("ROLE_ADMIN"))

  const [cookies, setCookie] = useCookies(['name']);
  const [canScan, setCanScan] = useState(false);

  useEffect(() => {
    //Cookies read annoucement
    setRead(cookies["annoucement_read"] || []);

    var read = cookies["annoucement_read"] || [];
    CheckConnDao.getAnnouncements(false).then((r) => {
      var arr = new Array();
      r.map(v => { if (!read.includes(v.id)) arr.push(v) })
      setList(arr);
    }, () => { });

    if (canNavigate) {
      setTimeout(() => {
        let nav = document.getElementById("searchEngine");
        if (nav)
          nav.querySelector("#ipt").focus()
      }, 200);
    }

    Miscellaneous.getStateByType("rotating_inventory_scan_ar").then(
      (r) => { if (r) setCanScan(r.value1 === "true") }
    )
  }, []);


  function getImgStyle() {
    //if (list.length === 0) return { width: "100%" };

    return { position: "fixed", width: "100%", left: "0", top: "60px" }
  }

  const markAsRead = (id, k) => {
    var arr = [...read];
    arr.push(id);
    setRead(arr);

    var cookiesExpiration = new Date();
    cookiesExpiration.setFullYear(new Date().getFullYear() + 1);

    setCookie("annoucement_read", arr, { expires: cookiesExpiration });

    var arr2 = [...list];
    arr2 = arrayRemoveElt(arr2, k);
    setList(arr2);
  }

  return (
    <Fragment>


      {
        typeDevice === "desktop" && <div>

          {canNavigate && <NavigationSearch />}
          <div className="container text-center mb-3">
            <img src="/common/logo_fond_ecran custom.png" style={getImgStyle()}></img>
          </div>

          {list.length > 0 && <div className="card" style={{ width: "50%", marginLeft: "35%", height: "50vh", overflowY: "auto" }}>

            <img src="/common/annoucement.png" style={{ width: "100px", height: "100px", position: "absolute" }} />

            <div className="offset-2">
              <ul className="list-group">
                {
                  list.map((v, k) => {
                    return !read.includes(v.id) && <li className="list-group-item" key={"l" + k}>
                      <div className="d-flex">
                        <div>
                          <div style={{ fontSize: "12px" }}>{getFrFormat(v.date)}</div>
                          <div dangerouslySetInnerHTML={{ __html: i18n.language === "fr" ? v.msg_fr : v.msg_en }}></div>
                        </div>
                        <div className="ms-auto">
                          <button type="button" className="btn-close" aria-label="Close"
                            onClick={() => markAsRead(v.id, k)}></button>
                        </div>
                      </div>
                    </li>
                  })
                }
              </ul>
            </div>

          </div>}
        </div>
      }

      {
        (typeDevice !== "desktop" && (user && user.roles && (user.roles.includes("ROLE_STOREHOUSE") || user.roles.includes("ROLE_ADMIN")))) &&
        <div className="text-center mb-3">
          <div className="container text-center mb-3">
            <img src="/Logo B+ Eq SealedAir.png" style={{ width: "100%" }}></img>
          </div>

          <h5 style={{ color: "rgb(0, 137, 150)", fontSize: "30px" }}>Mini Jarvis</h5>

          <a className="btn btn-outline-secondary mb-3"
            href="/storehouse/scan/move-out" style={{ minWidth: "220px", fontSize: "20px" }}>Sortie de pièces</a>
          <br></br>
          <a className="btn btn-outline-secondary mb-3"
            href="/storehouse/scan/move-in" style={{ minWidth: "220px", fontSize: "20px" }}>Entrée de pièces</a>
          <br></br>
          <a className="btn btn-outline-secondary mb-3"
            href="/storehouse/articles/inventory/scan" style={{ minWidth: "220px", fontSize: "20px" }}>Inventaire</a>
          <br></br>
          <a className="btn btn-outline-secondary mb-3"
            href="/subcontracting/scan" style={{ minWidth: "220px", fontSize: "20px" }}>Scan colisage</a>
          <br></br>
          <a className="btn btn-outline-secondary mb-3"
            href="/storehouse/rotating/inventory/scan" style={{ minWidth: "220px", fontSize: "20px" }}>Inventaire tournant</a>
          {canScan && <Fragment>
            <br></br>
            <a className="btn btn-outline-secondary mb-3"
              href="/storehouse/rotating/inventory/scan?page=scan_location" style={{ minWidth: "220px", fontSize: "20px" }}>Scan libre</a>
          </Fragment>
          }

        </div>
      }

      {
        (typeDevice !== "desktop" && (user && user.roles && (user.roles.includes("ROLE_TRANSPORT") || user.roles.includes("ROLE_TRANSPORT")))) &&
        <div className="text-center mb-3">
          <br></br>
          <h5 style={{ color: "rgb(0, 137, 150)", fontSize: "30px" }}>Mini Jarvis</h5>
          <a className="btn btn-outline-secondary mb-3"
            href="/storehouse/articles/screws/scan" style={{ minWidth: "220px", fontSize: "20px" }}>Commande visseries</a>
        </div>
      }

    </Fragment>
  );
};

export default Home;
