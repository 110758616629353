import { Fragment, useState } from "react";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../../common/smallComponents.js/SuccessMessage";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import ArticleInfo from "../../../services/articles/ArticleInfo";
import errorManagement from "../../../services/errorManagement";

const OldIndex = (p) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const user = JSON.parse(localStorage.getItem("user"));

    const updateField = (e, k, field) => {
        var ar = p.oldRef[k].CDARL;
        var old_value = p.oldRef[k][field] || "";
        var value = e.target.value;

        if( value === old_value ) return;

        ArticleInfo.updateOldRef(ar, field, value, old_value).then(
            (res) => {
                var arr = [...p.oldRef];
                arr[k][field] = value;
                p.setOldRef(arr);
            },(error) => {
                setError(errorManagement.handleError(p.props, error));
            }
        )
        
    }


    /** Separate user comments and system comments */
    function getUserComment( val ){
        if( !val ) return "";

        var idx = val.indexOf("----------");
        return idx !== -1 ? val.substring( idx+11, val.length ) : "";
    }

    const addArticle = (e) => {
        e.preventDefault();
        var ar = e.target.ar.value;

        setLoading(true);
        ArticleInfo.articlesInfo(ar).then(
            (response) => {
                if (response.length === 0) {
                    setError("Article inconnu");
                    scrollToTop();
                } else {
                    var arr = [...p.oldRef];
                    var o = { ...response[0] };
                    o.mode = "create";
                    arr.push(o);
                    p.setOldRef(arr);
                }
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(p, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    return <Fragment>
        {error && <ErrorMessage error={error} errorState={setError}/>}
        {msg && <SuccessMessage msg={msg} />}

        <div className="alert alert-info fw-bold text-center">Toutes les modification faites sur cet onglet sont désormais sauvegardées 
            sans avoir à valider les modifications
        </div>

        <table className="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Reférence</th>
                    <th scope="col">Libellé</th>
                    <th scope="col">Qté</th>
                    <th scope="col">Blocage</th>

                    <th scope="col">Remplacé en lieu et place par</th>
                    <th scope="col">Blocage à appliquer</th>
                    <th scope="col">Gestion du stock</th>
                    <th scope="col">Observation libre</th>
                </tr>
            </thead>
            <tbody>
                {
                    p.oldRef.map((v, k) => {
                        return <tr key={"old" + k}>
                            <th scope="row">
                                <a href={"/article/info/get?article=" + v.CDARL.trim()} target="_blank">{v.CDARL}</a>
                            </th>
                            <td>{v.ZN02PA}</td>
                            <td>{v.ZN03PA}</td>
                            <td>{parseInt(v.QTSTL) - parseInt(v.QTREL) + parseInt(v.QTCDL)}</td>
                            <td>{v.BLOCL === "A" ? "En appro" : v.BLOCL === "C" ? "Complet" : "Aucun"}</td>
                            <td>
                                <input className="form-control" onBlur={(e) => updateField(e, k, "replaced_by")} 
                                    defaultValue={v.replaced_by} placeholder="Code article"/>
                            </td>
                            <td>
                                <select className="form-control"
                                    onBlur={(e) => updateField(e, k, "blocking")} defaultValue={v.blocking}>
                                    <option selected="">Choose...</option>
                                    <option value="rep">En réapprovisionnement</option>
                                    <option value="tot">Total</option>
                                    <option value="no">Ne pas bloquer</option>
                                </select>
                            </td>
                            <td>
                                <select className="form-control"
                                    onBlur={(e) => updateField(e, k, "action")} defaultValue={v.action}>
                                    <option selected="">Choose...</option>
                                    <option value="bom">Sortir en nomenclature</option>
                                    <option value="sav">SAV (stock de sécu)</option>
                                    <option value="retrofit">À modifier (retrofit)</option>
                                    <option value="trash">À jeter</option>
                                </select>
                            </td>
                            <td>
                                <textarea className="form-control" defaultValue={getUserComment(v.com_ipt)}
                                    onBlur={(e) => updateField(e, k, "com_ipt_user")}></textarea>
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>

        <form onSubmit={addArticle} className="col-3">
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Ajouter un article</span>
                <input type="text" className="form-control" name="ar" />
                <button className="btn btn-outline-secondary" disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Rechercher
                </button>
            </div>
        </form>

    </Fragment>
}

export default OldIndex;
