import { Fragment, useEffect, useState } from "react";
import { SideNavBarV3, isSideBarOpened } from "../../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import getUrlParam from "../../../functions/StrFunctions";
import errorManagement from "../../../services/errorManagement";
import transport from "../../../services/supplyChain/transport";
import MachPicking from "./Machines/MachPicking";
import { downloadTempFile } from "../../../services/functions/FilesProcess";
import MachPacking from "./Machines/MachPacking";
import { confirmAlert } from "react-confirm-alert";
import FocusPicking from "./Machines/FocusPicking";

const PackingDocs = (p) => {
  const [pin, setPin] = useState(isSideBarOpened());
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState("picking");
  const [type, setType] = useState();
  const user = JSON.parse(localStorage.getItem("user"));
  const [changeDone, setChangeDone] = useState(false);

  const [value, setValue] = useState([]);
  const [picking, setPicking] = useState();

  const packingType = ["Conteneur", "Caisse", "Palette", "Colis", "Enveloppe", "Articles"];
  const [pickingId, setPickingId] = useState();

  useEffect(() => {
    var type = getUrlParam(p.props, "type");
    setType(type)
    if (type === "machine") {
      getMachinePicking()
    } else if (type === "focus") {
      getFocusPicking();
    }

  }, []);

  function getMachinePicking(picking_id = null) {
    transport.getPicking(getUrlParam(p.props, "shipment_id"), picking_id).then(
      (r) => {
        //console.log(r)
        setPicking(r);
        setPickingId(r.picking_id);

        setLoading(false);
      }, (error) => {
        setError(errorManagement.handleError(p.props, error));
        scrollToTop();
        setLoading(false);
      })
  }

  function getFocusPicking(picking_id = null) {
    transport.getFocusPicking(getUrlParam(p.props, "shipment_id"), picking_id).then(
      (r) => {
        console.log(r)
        setPicking(r);
        setLoading(false);
      }, (error) => {
        setError(errorManagement.handleError(p.props, error));
        scrollToTop();
        setLoading(false);
      })
  }

  const changePicking = (picking_id) => {
    setLoading(true);
    setPicking();
    setPickingId();

    if (type === "machine") { getMachinePicking(picking_id) }
  }

  const savePicking = (send = false) => {
    setError();
    var p = [...picking.machines];

    var arr = [];
    for (let i = 0; i < p.length; i++) arr = arr.concat(p[i].picking)

    setSaving(true);

    transport.savePicking(picking.shipment.id, arr, picking.picking_head, send).then(
      (r) => {
        var o = { ...picking };
        o.picking_id = r;
        o.picking_head.picking_id = r;
        if (!o.pickingList.includes(r)) o.pickingList.push(r);
        setPicking(o);

        setPickingId(r);

        setSaving(false);
        setChangeDone(false);
      }, (error) => {
        console.error(error)
        setError(errorManagement.handleError(p.props, error));
        scrollToTop();
        setSaving(false);
      })
  }

  const printHashTag = () => {
    var arr = [];

    for (let i = 0; i < picking.machines.length; i++) {
      var v = picking.machines[i];

      for (let j = 0; j < v.picking.length; j++) {
        arr.push({
          id: v.picking[j].id,
          machine: v.machine.no_machine
        });
      }
    }


    setSaving(true);
    transport.downloadHashTag(arr).then(
      (r) => {
        downloadTempFile(r)
        setSaving(false);
      }, (error) => {
        setError(errorManagement.handleError(p.props, error));
        scrollToTop();
        setSaving(false);
      })
  }

  const printPacking = () => {

    setSaving(true);
    transport.downloadPacking(picking, tab).then(
      (r) => {
        downloadTempFile(r)
        setSaving(false);
      }, (error) => {
        setError(errorManagement.handleError(p.props, error));
        scrollToTop();
        setSaving(false);
      })
  }

  /**
   * If you quite picking list, check save or not
   */
  const changeTab = (tab) => {
    //Check change if from picking

    setTab(tab);
  }

  function isSaved() { return !changeDone && pickingId !== null; }

  const deletePicking = () => {
    confirmAlert({
      title: "Suppression",
      message: "Confirmez-vous la suppression du picking n° " + pickingId + " ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            setSaving(true);
            transport.deletePicking(pickingId).then(
              (r) => {
                window.location.reload();
              }, (error) => {
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setSaving(false);
              }
            )
          }
        },
        {
          label: "Non"
        }
      ]
    });
  }

  return <div className="bg-see" style={{ height: "91.5vh", marginTop: "-1.5em" }}>
    <SideNavBarV3 setPin={setPin}
      content={<div>
        {picking &&
          <div className="accordion" id="accordionExample">

            <div className="accordion-item" onClick={() => setTab("picking")}>
              <h2 className="accordion-header">
                <button className={"accordion-button " + (isSaved() ? "" : "bg-warning")} type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Picking list
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">

                <div className="accordion-body cursor-pointer">
                  <img src="/common/print.png" className="me-1" style={{ width: "20px" }} />
                  <a href="#" onClick={() => window.print()}>Imprimer le picking</a>
                </div>

                <div className="accordion-body cursor-pointer" onClick={printHashTag}>
                  {saving && <ButtonWaiting />}
                  {!saving && <img src="/common/print.png" className="me-1" style={{ width: "20px" }} />}
                  <a href="#">Imprimer les ID (hash tag)</a>
                </div>

                {picking.pickingList.length > 0 && <div>
                  <h6 className="text-center">Autre picking</h6>

                  <div className="mb-3 ps-3">
                    {picking.pickingList.map((v, k) => {
                      return v !== pickingId && <li key={"pick" + k} onClick={() => changePicking(v)}>Picking n°{v}</li>
                    })}

                    <li onClick={() => changePicking("new")}>Faire une nouveau picking</li>
                  </div>

                </div>}

                {pickingId && <div className="mt-3 text-center mb-3">
                  <br></br>
                  <button className="btn btn-outline-danger" disabled={saving} onClick={deletePicking}>
                    {saving && <ButtonWaiting />}
                    Supprimer ce picking</button>
                </div>}
              </div>
            </div>

            <Fragment>
              <div className="accordion-item" onClick={() => changeTab("packing")}>
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Packing list
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">

                    {!isSaved() && <div className="text-danger fw-bold text-center">
                      ATTENTION: picking non enregistré!
                    </div>}

                    {picking.picking_id &&
                      <div className="accordion-body cursor-pointer">
                        {saving && <ButtonWaiting />}
                        {!saving && <img src="/common/print.png" className="me-1" style={{ width: "20px" }} />}
                        <a href="#" onClick={printPacking}>Imprimer la packing list</a>
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div className="accordion-item" onClick={() => changeTab("delivery note")}>
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Bordereau d'expédition
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">

                  <div className="accordion-body">
                    {!isSaved() && <div className="text-danger fw-bold text-center">
                      ATTENTION: picking non enregistré!
                    </div>}
                    {picking.picking_id &&
                      <div>
                        {saving && <ButtonWaiting />}
                        {!saving && <img src="/common/print.png" className="me-1" style={{ width: "20px" }} />}
                        <a href="#" onClick={printPacking}>Imprimer la packing list</a>
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Custom invoice
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body"></div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Déclaration de conteneur
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body"></div>
                </div>
              </div>

              <div className="mt-3 text-center">
                <a href="/supply-chain/tools/expedition">Revenir à l'accueil</a>
              </div>
            </Fragment>

          </div>}
      </div>} />

    <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
      {loading && <WaitingRoundSnippers />}
      {error && <ErrorMessage error={error} errorState={setError} />}

      {(type && tab) && <Fragment>

        {/**Machines */}
        {type === "machine" && <Fragment>
          {tab === "picking" && <MachPicking picking={picking} setPicking={setPicking} pickingId={pickingId}
            setChangeDone={setChangeDone} savePicking={savePicking} saving={saving} />}
          {(tab === "packing" || tab === "delivery note") && <MachPacking picking={picking} tab={tab} />}

        </Fragment>}

        {/**Focus */}
        {type === "focus" && <Fragment>
            <FocusPicking picking={picking} setPicking={setPicking} pickingId={pickingId}/>
        </Fragment>}

      </Fragment>}


    </div>

  </div >


}

export default PackingDocs;