import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomToolTip from "../../common/smallComponents.js/CustomToolTip";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import { ButtonWaiting } from "../../functions/PageAnimation";
import { getFrFormat, updateDrawingUrl } from "../../functions/StrFunctions";
import i18n from "../../i18n";
import ArticleInfo from "../../services/articles/ArticleInfo";
import errorManagement from "../../services/errorManagement";

const SearchArticle = (props) => {
    const { t } = useTranslation('article');
    const user = JSON.parse(localStorage.getItem("user"));
    const [isInternal] = useState(user && user.roles.includes("ROLE_INTERNAL"));
    const [isPurchase] = useState(user && user.roles.includes("ROLE_PURCHASE"));
    const isSee = useState(user && user.roles.includes("ROLE_SEE"));
    const [searching, setSearching] = useState(false);
    const [error, setError] = useState();
    const [data, setData] = useState([]);
    const token = user && "Bearer " + user.accessToken;
    const [nbResult, setNbResult] = useState(100);
    const [invisible, setInvisble] = useState(false);

    const API_URL = process.env.REACT_APP_API_URL;
    var url = API_URL + "articles/info/get-drawing?Authorization=" + token + "&article=";
    var url2 = API_URL + "articles/info/get-drawing-url?Authorization=" + token + "&url=";

    useEffect(() => {
        document.getElementById("searchIpt").focus();
    }, []);

    const search = (e) => {
        e.preventDefault();
        setError();
        setData([]);

        var term = e.target.term.value;

        setSearching(true);
        setNbResult(100);
        ArticleInfo.searchArticle(term, i18n.language, false).then(
            (response) => {
                setData(response)
                setSearching(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearching(false);
            }
        )
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('ar', {
            header: t('ar_code'),
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        if( isSee ){
            arr.push(columnHelper.accessor('visual_id', {
                header: "Visual ID",
                cell: i => <div className={"text-center "}>{i.getValue()}</div>
            }));

            arr.push(columnHelper.accessor('visual_desc', {
                header: "Visual desc.",
                cell: i => <div className={"text-center "}>{i.getValue()}</div>
            }));
        }

        arr.push(columnHelper.accessor('com_ref', {
            header: t('com_lab'),
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('tec_ref', {
            header: t('tec_lab'),
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));
        
        arr.push(columnHelper.accessor('plan', {
            header: t('plan'),
            cell: i => <div className={"text-center "}>
                <Fragment>
                    {
                        (i.row.original.pdf_plan !== null && i.row.original.pdf_plan !== 'ignore') &&
                        <a target="_blank" href={url2 + updateDrawingUrl(i.row.original.pdf_plan)}>{i.getValue()}</a>

                    }

                    {
                        (i.row.original.pdf_plan === null || i.row.original.pdf_plan === 'ignore') &&
                        <span>{i.getValue()}</span>
                    }
                </Fragment>
            </div>
        }));

        if (isInternal) {
            arr.push(columnHelper.accessor('qty', {
                header: t('qty_avai'),
                cell: i => <div className={"text-center "}>{i.getValue()}</div>
            }));

            arr.push(columnHelper.accessor('needs_tot', {
                header: t('qty_reserv'),
                cell: i => <div className={"text-center "}>{i.getValue()}</div>
            }));

            arr.push(columnHelper.accessor('remain_qty', {
                header: t('qty_at_term'),
                cell: i => <div className={"text-center "}>{i.getValue()}</div>
            }));

            arr.push(columnHelper.accessor('pxrel', {
                header: "Prix de revient",
                cell: i => <div className={"text-center "}>
                    <div>{i.getValue()} €</div>
                    {i.row.original.last_price !== null &&
                    <div className="fst-italic" style={{fontSize: "12px"}}>Dernier prix achat: {i.row.original.last_price} €</div>}
                </div>
            }));
        }

        if (isPurchase) {
            arr.push(columnHelper.accessor('xxxx', {
                header: "",
                cell: i => <div className={"text-center "}>
                    <a target="_blank" href={"/article/info/stock/dynamic?article=" + i.row.original.ar.trim()}>Stock dynamique</a>
                </div>
            }));
        }        

        arr.push(columnHelper.accessor('DCREL', {
            header: "Date de création",
            cell: i => <div className={"text-center "}>{getFrFormat( i.getValue(), true)}</div>
        }));

        if (isInternal) {
            arr.push(columnHelper.accessor('comments', {
                header: "Observations",
                cell: i => <div className={"text-center "}>
                    <Fragment>
                        {i.getValue() !== null &&
                            <div className="mb-1">
                                <CustomToolTip props={
                                    {
                                        tooltipText: i.getValue(),
                                        originText: i.getValue().substring(0, 31) + (i.getValue().length > 31 ? "..." : "")
                                    }} />
                            </div>
                        }

                        {
                            ((user.roles.includes("ROLE_NC_READ") || user.roles.includes("ROLE_NC_WRITE") || user.roles.includes("ROLE_ADMIN"))
                                && i.row.original.no_nc) && <div>
                                <a href={"/storehouse/quality/nc?id=" + i.row.original.no_nc} target="_blank"
                                    className="btn btn-outline-danger">NC en cours</a>
                            </div>
                        }
                    </Fragment>
                </div>
            }));
        }


        arr.push(columnHelper.accessor('xxx', {
            header: "",
            cell: i => <div className={"text-center "}>
                <a target="_blank" href={"/article/info/get?article=" + i.row.original.ar.trim()}
                    className="btn btn-success">{t('enter')}</a>
            </div>
        }));



        return arr;
    }

    const splittedSearch = (e) => {
        e.preventDefault();
        var ar = e.target.arCode.value;
        var label = e.target.label.value;
        var ref = e.target.ref.value;
        var ref_frn = e.target.ref_frn.value;
        var nbRecords = e.target.nbRecords.value;
        setData([]);
        setError();


        setSearching(true);
        setNbResult(nbRecords);
        ArticleInfo.searchSplittedArticle(ar, label, ref, ref_frn, nbRecords, i18n.language, invisible).then(
            (response) => {
                setData(response)
                setSearching(false);
                document.getElementById('collapseBtn').click();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearching(false);
            }
        )


    }

    //Set columns
    const columns = getColumns();

    return <Fragment>

        <h6 className="text-center">{t('article search')}</h6>

        <div className="accordion col-10 offset-1 mb-3" id="accordionExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                    <form onSubmit={search}>

                        <div className="col-10 offset-1">
                            <div className="p-2 input-group mb-3">
                                <input type="text" className="form-control text-center fw-bold" id="searchIpt" autoComplete="off"
                                    placeholder={t('ar_code') + ", " + t('com_lab') + ", " + t('tec_lab') + ", " + t('plan')} name="term" />
                                <button className="btn btn-outline-success" disabled={searching}>
                                    {searching && <ButtonWaiting />}
                                    {t('search')}
                                </button>
                            </div>
                        </div>
                    </form>

                    <button className="accordion-button collapsed text-center fw-bold" id="collapseBtn" style={{height: "0px"}}
                        type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span style={{ margin: "auto", width: "100%", marginTop: "-10px" }}>More</span>
                    </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <form onSubmit={splittedSearch}>
                            <div className="display-flex-wrap mb-3">
                                <div className="form-floating mb-3" style={{ margin: "auto", width: "30%", minWidth: "150px" }}>
                                    <input type="text" className="form-control" id="arCodeInput"
                                        placeholder="xxx" name="arCode" />
                                    <label htmlFor="arCodeInput">{t('ar_code')}</label>
                                </div>

                                <div className="form-floating mb-3" style={{ margin: "auto", width: "30%", minWidth: "150px" }}>
                                    <input type="text" className="form-control" id="labelInput"
                                        placeholder="xxx" name="label" />
                                    <label htmlFor="labelInput">{t('com_lab')}</label>
                                </div>

                                <div className="form-floating mb-3" style={{ margin: "auto", width: "30%", minWidth: "150px" }}>
                                    <input type="text" className="form-control" id="refInput"
                                        placeholder="xxx" name="ref" />
                                    <label htmlFor="refInput">{t('tec_lab')} / {t('plan')}</label>
                                </div>

                                <div className="form-floating mb-3" style={{ margin: "auto", width: "30%", minWidth: "150px" }}>
                                    <input type="text" className="form-control" id="refInput"
                                        placeholder="xxx" name="ref_frn" />
                                    <label htmlFor="refInput">Ref. fournisseur (achats)</label>
                                </div>

                                <div className="form-floating" style={{ margin: "auto", width: "30%", minWidth: "150px" }}>
                                    <select className="form-select" id="floatingSelect" name="nbRecords">
                                        <option value="50">50</option>
                                        <option value="500">500</option>
                                        <option value="5000">2000</option>
                                    </select>
                                    <label for="floatingSelect">Number of records</label>
                                </div>

                                <div className="form-check d-flex">
                                    <input className="form-check-input mt-auto mb-auto me-1" 
                                        type="checkbox" checked={invisible} onChange={()=>setInvisble(!invisible)}/>
                                    <label className="form-check-label mt-auto mb-auto">
                                        Afficher articles invisibles
                                    </label>
                                </div>
                            </div>

                            <div className="text-center">
                                <button className="btn btn-success" disabled={searching}>
                                    {searching && <ButtonWaiting />}
                                    Search
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        {/*<div className="h-divider">
            <div className="shadow-divider"></div>
</div>*/}

        {data.length >= 50 && <div className="text-center fw-bold">{nbResult + t('fifty-first')}</div>}

        {error && <ErrorMessage error={error} errorState={setError} />}

        {/*data.length > 0 && <Table columns={columns} data={data} />*/}

        {data.length > 0 && <ReactTable columns={columns} data={data}
            origin="art_search" classes={"no-padding"} top0={true} />}


    </Fragment>

}

export default SearchArticle;